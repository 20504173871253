.privacy-policy-container {
  padding: 20px;
  margin: 20px auto;
  max-width: 900px;
  border-radius: 8px;
}

.privacy-policy-container h1 {
  margin-bottom: 1rem;
}

.privacy-policy-container h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-decoration: underline;
}

.privacy-policy-container p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0.75rem;
}

.privacy-policy-container ul {
  padding-left: 20px;
}

.privacy-policy-container li {
  margin-bottom: 0.25rem;
}
