/* Seo Section */

/* Hero Message */

.hero-message {
  margin-bottom: 50px;
}

/* Stats */

.stats {
  background-color: #0055b8;
  color: white;
}

/* Tuition Rates */

.tuition-rates {
  padding-top: 100px;
}

/* Schedule */

.schedule {
  background-color: #0055b8;
}

/* FAQ Page */

.faq-page {
  padding-bottom: 50px;
}

/* Google Form */
.google-form {
  padding-bottom: 125px;
}

/* Contact Us */
.contact-us {
  background-color: #0055b8;
}

@media (max-width: 768px) {
  .floating-sign-up-btn {
    padding: 10px;
    width: 50px;
    height: 50px;
    font-size: 0.8rem;
  }

  .faq-page {
    padding-bottom: 25px;
  }
}
