/* FAQ.css */

/* General page styles */
.faq-page {
  max-width: 800px;
  margin: 0 auto;
}

/* Page title styles */
.faq-page h1 {
  text-align: center;
  margin-bottom: 60px;
  font-size: 2.7rem;
}

/* FAQ item styles */
.faq-item {
  background-color: #f9f9f9;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* FAQ question styles */
.faq-question {
  padding: 15px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  user-select: none;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: left;
  font-size: 1.5rem;
}

/* FAQ answer styles */
.faq-answer {
  padding: 15px;
  margin: 0;
  border-top: 1px solid #ddd;
  font-size: 1.5rem;
  line-height: 1.7;
}

.faq-question::before {
  content: "+";
  margin-right: 10px;
  font-size: 1.5em;
  line-height: 0.8;
}

.faq-item.open .faq-question::before {
  content: "-";
}
