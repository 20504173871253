.site-footer p {
  color: white;
  margin: 0;
}

.site-footer {
  background-color: #000000;
  text-align: center;
  padding: 10px 0;
  margin-top: auto; /* Push footer to the bottom */
}
