.rates-header {
  font-size: 2.6rem;
}

.rates-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 200px;
}

.rates-buttons {
  text-align: center;
  margin-bottom: 20px;
}

.rates-buttons button {
  border: none;
  outline: none;
  padding: 10px 20px;
  margin: 0 20px;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: white;
  background-color: #66cc99;
}

.rates-buttons button:hover {
  background-color: #66cc99;
}

.rates-buttons button:focus {
  outline: none;
  box-shadow: none;
}

.rate-level {
  margin-bottom: 30px;
  text-align: center;
}

.rate-level-text {
  font-size: 2rem;
}

.rate-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.rate-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #8abcf5;
  transition: background-color 0.3s ease-in-out, transform 0.3s, box-shadow 0.3s;
  width: 225px;
  min-height: 150px;
  margin: 20px;
}

.rate-card:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background-color: #f8ee8e;
}

.rate-card-subject {
  color: #000000;
  font-size: 1.7rem;
  margin-bottom: 0;
}

.rate-card-price {
  font-size: 1.75rem;
  font-weight: bold;
  color: #0055b8;
}

.rate-card-bonus {
  padding: 0;
  margin: 10px 0;
  list-style: none;
  font-size: 1.25rem;
}

.rate-card-bonus li {
  background-color: #f9f9f9;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-bottom: 2px solid #d4d9de;
  position: relative;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .rates-buttons {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .rates-buttons button {
    margin-bottom: 1rem;
  }
}
